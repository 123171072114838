<template>
  <!--下载商品检验报告-->
  <div class="download-loding" v-if="isDownload">
    <div class="download-loding-wrap">
      <div class="download-loding-title">{{isDownloadType === 1 ? "下载商品检验报告" : "下载产品资料"}}</div>
      <div class="form">
        <div class="means-title">
          <p class="ptitle">商品名称</p>
          <p class="ptitle">规格</p>
          <p class="ptitle">生产厂家</p>
          <!--<p class="ptitle">生产批号</p>-->
        </div>
        <ul>
          <li v-for="(item, index) in productInformationList" :key="index" class="means-item clearfix">
            <b-checkbox size="is-small" type="is-info" v-model="item.isCheckedName" @input="getSelectItem" />
            <span class="name">{{ item.productName }}</span>
            <span class="name">{{ item.specifications }}</span>
            <span class="name" style="line-height: 20px">{{ item.produceFactory }}</span>
            <!--<span class="name">{{ item.batchNumber || "&#45;&#45;" }}</span>-->
            <span class="downFlag">{{ item.isDownload ? "已下载" : "未下载" }}</span>
          </li>
        </ul>
      </div>
      <div style="display: flex; justify-content: space-between; padding: 0 52px 0 56px; margin-top: 15px">
        <div style="display: flex; align-items: center;">
          <b-checkbox size="is-small" type="is-info" v-model="selectAll" @input="getSelectAll">全选</b-checkbox>
          <span class="text">共{{ arr.length }}个文件</span>
          <!--<span class="text" style="margin-left: 0">，总计{{ checkedAllSize }}M</span>-->
        </div>
        <div class="buttons">
          <span @click="handleCancelDownload">取消</span>
          <span v-if="hasChecked" @click="handleConfirmDownload">确定</span>
          <span v-else class="form-confirm-den">确定</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import publicMethods from '@mixin/publicMethods'
import { AccountStorage } from "@services/account";
const accountStorage = new AccountStorage();

export default {
  components: {},
  data() {
    return {
      isDownload: false,
      // 1.下载商品检验报告  2.下载产品资料
      isDownloadType: 1,
      arr: [],
      productIds: [],
      userId: '',
      ids: [],
      selectAll: false,
      hasChecked: false,
    }
  },
  props: {
    commodityList: {
      type: Array,
      default: () => []
    },
    data: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    checkedAllSize() {
      let val = 0;
      if (this.arr.length) {
        this.productInformationList.forEach(item => {
          this.arr.forEach(v => {
            if (item.pictureUrl === v.pictureUrl) {
              val += Number(item.pictureSize);
            }
          });
        });
      }
      return val.toFixed(1);
    }
  },
  mixins: [publicMethods],
  created() {
    let newUserId = accountStorage.getCacheUserInfo();
    this.userId = newUserId.userId;
  },
  methods: {
    // 获取报告
    getInspectionReport(val) {
      // 1.下载商品检验报告  2.下载产品资料
      this.isDownloadType = val;
      this.isDownload = true;

      let params, url;
      if (this.isDownloadType == 1) {
        this.productIds = this.commodityList.map(item => item.productId)
        url = `/pjyy-deepexi-product-center/api/v1/product/drug/findAll`;
        params = {
          productIds: this.productIds,
          orderid: this.data.id.toString(),
          type: this.isDownloadType === 1 ? 1 : 4,
          erpOrderId: this.data.erpOrderId
        };
      } else if (this.isDownloadType == 2) {
        url = `/pjyy-deepexi-product-center/api/v1/product/infoClass/queryProductInfoDownListByErpOrderId`;
        params = {
          erpOrderId: this.data.erpOrderId
        };
      }

      this.$getHttpClient().post(url, params).then(res => {
        if (res.data.code === "200") {
          this.productInformationList = res.data.data;
          this.productInformationList.forEach(item => item.isCheckedName = false)
        }
      });
    },

    // 单个勾选
    getSelectItem() {
      let arr = [];
      this.productInformationList.forEach(item => {
        if (item.isCheckedName) {
          arr.push(item);
        }
      });
      this.arr = arr;
      this.selectAll = this.productInformationList.every(item => item.isCheckedName === true);
      this.hasChecked = this.productInformationList.some(item => item.isCheckedName === true);
    },

    // 全选
    getSelectAll(val) {
      this.arr = [];
      this.productInformationList.forEach(item => {
        item.isCheckedName = val;
        if (item.isCheckedName) {
          this.arr.push(item);
        }
      });
      this.selectAll = this.productInformationList.every(item => item.isCheckedName === true);
      this.hasChecked = this.productInformationList.some(item => item.isCheckedName === true);
    },

    // 下载取消按钮
    handleCancelDownload() {
      this.isDownload = false;
      this.selectAll = false;
      this.ids = [];
      this.productIds = [];
    },

    // 下载报告全选确定
    handleConfirmDownload() {
      this.handleCancelDownload()
      let batchNumbers = [], productCodes = [];
      this.productInformationList.forEach(item => {
        if (item.isCheckedName) {
          this.ids.push(item.productId);
          batchNumbers.push(item.batchNumber);
          productCodes.push(item.productCode);
        }
      });
      // 1.下载商品检验报告  2.下载产品资料
      if (this.isDownloadType == 1) {
        if (this.ids.length == 0) {
          this.isDownload = true;
          this.$message.warning('请选择要下载的商品')
          return;
        }
        this.downloadDrugPc(batchNumbers)
      } else {
        // 下载产品资料
        this.downloadProductInformation(productCodes, this.data.erpOrderId)
      }
    },

    downloadDrugPc(batchNumbers) {
      let params = `?orderId=${this.data.erpOrderId}&productIds=${this.ids}&batchNumber=${batchNumbers}&userId=${this.userId}`
      let url = `/pjyy-deepexi-product-center/api/v1/product/drug/downloadDrugPc${params}`;
      let xhr = new XMLHttpRequest()
      xhr.open('get', url, true)
      xhr.setRequestHeader('Content-Type', `application/zip`)
      xhr.responseType = 'blob'
      this.showLoading = true;
      xhr.onload = () => {
        if (xhr.status == 200) {
          //接受二进制文件流
          const blob = new Blob([xhr.response], { type: 'application/zip' });
          const url = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = `${this.data.erpOrderId}检验报告`;
          link.click();
          URL.revokeObjectURL(url);
          this.showLoading = false;
        }
      }
      xhr.send()
    }
  }
}
</script>

<style scoped lang="scss">
.download-loding {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  z-index: 1200;

  .download-loding-wrap {
    width: 865px;
    height: 600px;
    background: rgba(255, 255, 255, 1);
    border-radius: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .download-loding-title {
      height: 40px;
      line-height: 40px;
      text-align: left;
      padding-left: 20px;
      font-size: 16px;
      font-weight: 500;
      color: rgba(255, 255, 255, 1);
      background: #3893fd;
      border-radius: 20px 20px 0px 0px;
      margin-bottom: 20px;
    }

    .form {
      height: 450px;
      overflow: hidden;
      overflow-y: auto;
      padding: 16px 57px 0;
    }

    .means-title {
      width: 86.4%;
      font-size: 18px;
      font-weight: 500;
      display: flex;
      justify-content: flex-start;
      text-align: center;
      margin-left: 32px;

      .ptitle {
        flex: 1;
      }
    }

    ul {
      margin-top: 20px;

      .means-item {
        width: 100%;
        margin-bottom: 20px;
        color: #333;
        font-size: 14px;
        display: flex;
        align-items: center;

        .name {
          text-align: center;
          display: inline-block;
          flex: 1;
        }

        .downFlag {
          width: 68px;
          line-height: 30px;
          text-align: center;
          border: 1px solid #f45757;
          border-radius: 20px;
          color: #f45757;
        }
      }

      li {
        display: flex;
        justify-content: space-around;
      }
    }

    .text {
      color: #666;
      font-size: 12px;
      margin-left: 20px;
    }

    .buttons {
      display: flex;
      justify-content: flex-end;
      font-size: 16px;
      font-weight: 500;
      color: #fff;

      span {
        width: 120px;
        height: 36px;
        line-height: 36px;
        border-radius: 4px;
        background: #3893fd !important;
        margin-right: 20px;
        text-align: center;
        color: rgba(255, 255, 255, 1);
        cursor: pointer;

        &:last-child {
          margin-right: 0;
        }
      }

      .form-confirm-den {
        cursor: not-allowed;
      }
    }
  }
}
</style>
