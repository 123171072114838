<template>
  <div>
    <PageTop1></PageTop1>
    <PageTop></PageTop>
    <div class="order v2-g-w1200">
      <div class="order-detail-title">
        <span class="shop-color-theme" @click="toBack">个人中心&nbsp;</span>
        <i class="iconfont icon-youjiantou shop-color-theme"></i>
        <span class="shop-color-theme" @click="$router.push({ name: 'v2-order', params: { status: '0' } })">订单管理</span>
        <i class="iconfont icon-youjiantou shop-color-theme"></i>
        <span>{{ $route.name == `v2-orderpage` ? '订单详情' : '' }}</span>
      </div>

      <div class="order-detail-message">
        <div class="order-detail-information">
          <div class="information-wrap">
            <div class="information-title">
              <div class="information-title-b">订单信息</div>
              <div class="v2-g-flex-row">
                <div class="download" @click="handleDownload(2)">下载产品资料</div>
                <div class="download" @click="handleDownload(1)">下载商品检验报告</div>
                <!--订单状态（orderStatus） 01:待支付 02:待发货 03:运输中 04:已完成 05:已取消-->
                <template v-if="data.orderStatus=='03' || data.orderStatus=='04'">
                  <!--<div class="download" @click="showTicket(2)">下载开货明细</div>-->
                  <div class="download" @click="handleWithGoodTicket(1)">下载随货同行单</div>
                  <div class="download" style="width: 180px" @click="handleWithGoodTicket(2)">下载随货同行单（附检验报告）</div>
                </template>

                <div class="download" @click="showTicket(1)" v-if="(data.orderStatus=='03' || data.orderStatus=='04') && (data.invoice == 1 || data.invoice == 2) ">
                    查看发票
                </div>
                <div class="download" @click="onCloseOrder" v-if="(data.orderStatus === '01' || data.orderStatus === '02') && data.erpOrderType != '6'">
                    取消订单
                </div>
              </div>
            </div>
            <div class="information-Big">
              <div>
                <p>
                  订单编号: <span>{{ data.erpSaleOrderId || " --" }}
                  <span class="shop-color-theme" style="cursor: pointer" @click="copyData(data.erpSaleOrderId)" v-if="data.orderNumber != ''">&nbsp;&nbsp;复制</span></span>
                </p>
                <p>下单时间: <span>{{ data.orderAt }}</span>
                </p>
                <p>
                  发货数量: <span>{{ data.deliveryNumber == null ? 0 : data.deliveryNumber }}</span>
                </p>
              </div>
              <div>
                <p>
                  配送方式: <span>{{ data.distributionWay | distributionWay }}</span>
                </p>
                <p v-if="data.orderStatus === '01' && data.payWay === 'OFFLINEPAY'">
                  订单状态: <span>线下付款</span>
                </p>
                  <p v-else>
                      订单状态: <span>{{ data.orderStatus | statusName }} </span>
                  </p>
                <p class="order-tag" v-if="data.supplierName">
                    {{data.supplierName}}代下单
                </p>
              </div>
              <div>
                <p>
                  总品规数: <span v-if="data.orderLines">{{ data.orderLines.length }}</span>
                  <span style="color: red; margin-left: 7px;" v-if="data.giftOrderNum">(赠品×{{ data.giftOrderNum }})</span>
                </p>
                <p v-if="data.invoice == 1 || data.invoice == 2">
                    开具发票: <span>{{ data.invoice | invoice }}</span>
                </p>
              </div>
              <div>
                <p v-if="data.companyName">
                  客户名称: <span class="companyName">{{ data.companyName }}</span>
                </p>
                <p>
                  订单来源: <span>{{ data.orderSource | orderSource }}</span>
                </p>
                <p>
                    订单类型: <span>{{ data.erpOrderType | orderTypeName }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="order-detail-payment">
          <div class="payment-wrap">
            <div class="payment-title">付款信息</div>
            <div class="payment-tab">
              <div>
                <p>
                  支付方式: <span style="padding-left:4px">{{ data.payWay || data.payType | payWay }}</span>
                </p>
                <p>
                  实付金额: <span style="padding-left:4px"
                                  v-if="data.realPayAmount != null && (data.payWay == 'ALIPAY' || data.payWay == 'WXPAY')">
                  ¥{{ Number(data.realPayAmount) | NumFormat }}</span>
                  <span v-else-if="data.payWay != 'ALIPAY' && data.payWay != 'WXPAY'">
                    {{ Number(data.realPayAmount) | NumFormat }}
                  </span>
                </p>
                  <p v-if="data.activityPriceFor99 && data.activityPriceFor99 > 0">
                      满99返5: <span style="padding-left:8px;color: #FF0000;font-weight: 400">¥{{ data.activityPriceFor99 }}</span>
                  </p>
              </div>

              <div>
                <p>
                  总金额: <span style="padding-left:4px">¥{{ Number(data.totalAmount) | NumFormat }}</span>
                </p>
                <p>
                  特价立减: <span style="padding-left:4px">
                  ¥{{ data.totalReductionAmount == null ? '0.00' : data.totalReductionAmount }}
                </span>
                </p>
                <p v-if="data.typeRedBag && data.typeRedBag > 0">
                  品种红包: <span style="padding-left:8px;color: #FF0000;font-weight: 400">¥{{ data.typeRedBag }}</span>
                </p>
              </div>

              <div>
                <p v-if="data.isShow === 1">
                  299凑单返利:
                  <span style="padding-left:4px;color: #FF0000;font-weight: 400">¥{{ data.giftAmount }}</span>
                  <a class="rebateDetails" v-show="data.orderSource == '2' && data.giftAmount > 0"
                    @click="lookDetails">查看明细</a>
                </p>
                <p v-if="data.secondaryRebate">
                  整单立减：
                  <span style="color: #FF0000;">¥{{ data.secondaryRebate }}</span>
                </p>
                <p>
                  余额抵扣: <span style="padding-left:8px">¥{{ data.balanceAmount }}</span>
                </p>
              </div>

              <div>
                <p>
                  运费: <span style="padding-left:4px">¥{{ Number(data.freight) | NumFormat }}</span>
                </p>
                <p>
                  优惠券: <span style="padding-left:4px">¥{{ Number(data.couponPrice) | NumFormat }}</span>
                </p>
              </div>

              <div>
                <p v-if="data.cashAmount">
                  现金返点: <span style="padding-left:8px">¥{{ data.cashAmount }}</span>
                </p>
                <p v-if="data.onlinePaymentInstantDiscount">
                  线上支付优惠: <span style="padding-left:4px">¥{{ data.onlinePaymentInstantDiscount }}</span>
                </p>
                <p>
                  应付金额:
                  <span style="padding-left:4px;color: #e7523f;" v-if="data.realPaidAmount != null">
                    ¥{{ Number(data.realPaidAmount) | NumFormat }}
                  </span>
                </p>
              </div>

              <div>
                <p v-if="data.payType === 'ONLINE'">
                  支付状态: <span style="padding-left:4px">{{ payStatus[data.payStatus || "WAIT"] }}</span>
                </p>
                <p v-if="data.payType === 'ONLINE'">
                  支付号: <span style="padding-left:4px">{{ data.cpcnTradeNo == null ? '--' : data.cpcnTradeNo }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="order-detail-consignee">
          <div class="consignee-wrap">
            <div class="consignee-title">收货人信息</div>
            <div class="consignee-tab">
              <div class="consignee">
                <p>联系人: </p>
                <p>{{ data.userName }}</p>
              </div>
              <!--<div class="consignee-phone">-->
              <!--  <p>联系方式: </p>-->
              <!--  <p>{{ data.phoneNumber }}</p>-->
              <!--</div>-->
              <div class="consignee-address">
                <p>收货地址: </p>
                <p>{{ data.distributionAddr }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="logisticsBox" v-if="data.waybillNo">
            <div v-if="tracesData && tracesData.length > 0">
              <div class="logisticsHeader displayFlexBetween">
                  <div class="displayFlex">
                      <div class="lh-icon displayFlexCenter">
                          <i class="iconfont icon-dituche car"></i>
                      </div>
                      <div class="lh-title">
                          <span>{{tracesData[0].opeName}}</span>
                          <span>{{tracesData[0].waybillCode}}</span>
                      </div>
                  </div>
                  <div class="lh-copy" @click="copyData(tracesData[0].waybillCode)">
                      复制
                  </div>
              </div>
              <div class="logisticsTrack" :style="{'height': logisticsTrackHeight}">
                  <div v-for="(item, index) in tracesData" :key="index">
                      <trackNode :is-first="index === tracesData.length - 1" :is-newest="item.isActive" :node-data="item"></trackNode>
                  </div>
              </div>
              <div class="readMoreBox displayFlexCenter" @click="readMore">
                  <span>{{readMoreTxt}}</span>
                  <span class="iconfont icon-xiajiantou icon"
                        :style="{'transform': isShowMore ? 'rotate(180deg)' : 'rotate(0deg)'}"></span>
              </div>
            </div>
        </div>

      <div class="order-detail-list">
        <div class="detail-list-title">
          <div class="detail-list-title-r">商品信息</div>
          <div class="search-box">
            <div class="search-inp">
              <input type="text" placeholder="在当前结果中搜索" @input="searchProduct(large)" v-model="large" />
            </div>
            <div class="search-img">
              <span class="iconfont icon-search" @click="searchProduct(large)">&nbsp;搜索</span>
            </div>
          </div>
        </div>

        <div class="product-list">
          <div class="product-columns">
            <p class="columns-product">商品</p>
            <p class="columns-price">单价</p>
            <p class="columns-num">订单数量</p>
            <p class="columns-subtotal">小计</p>
            <p class="product-practical" v-if="data.orderStatus == '03' || data.orderStatus == '04'">
              发货数量
            </p>
            <p class="columns-handle">操作</p>
          </div>

          <!-- 商品info -->
          <div class="shopp-product-list-box">
            <div class="shopp-product" style="flex-direction: column;" v-for="(item, index) in productListRst" :key="index">
              <div style="display: flex; align-items: center; justify-content: space-between; width: 100%; margin-top: 10px;">
                <div class="product-info">
                  <div class="product-info-main">
                    <!-- 商品图片 -->
                    <div class="product-img-box">
                      <div class="product-img">
                        <img :src="item.productPhoto" alt />
                        <div class="product-info_giveaway" style="width: 100%;" v-if="item.isGift == 1">赠品</div>
                      </div>
                      <!--零差价标识-->
                      <div class="zero-price-img" v-if="item.isZeroPriceDifference == 1">
                        <img src="@images/PC0.png">
                      </div>
                      <div class="product-hbimg-jf" v-if="item.productAttribute == '1'">
                        返利
                      </div>
                      <img v-if="item.productPatent && item.productPatent == '1'" class="db_icon"
                           src="https://obs.pujian.com/frontend/app/components/db_icon.png"
                           alt />
                    </div>
                    <!-- 商品描述 -->
                    <div class="product-introduce">
                      <p><span class="jyb jyStyle" v-if="item.isBaseDrug == '1'">基药</span>
                        <span class="jyb cjStyle" v-if="item.isCentralizedProcurement == '1'">集采</span>
                        【{{ item.specifications }}】{{ item.productName }}</p>
                      <p>{{ item.produceFactory }}</p>
                      <p v-if="item.nearEffectivePeriod">
                        效期
                        {{ item.nearEffectivePeriod | formatDate }}
                      </p>
                      <p v-else>效期</p>
                      <p class="lastTime" v-if="data.orderStatus === '01' || data.orderStatus === '02'">
                        最后退货时间：{{ item.lastReturnTime }}
                      </p>
                    </div>
                  </div>
                </div>

                <!-- 商品价格 -->
                <div class="product-price" v-if="item.superDiscountProductPrice">
                  <template v-if="item.isMediumPackage == 1">
                    <span>均价¥{{ item | filterMoney1 }}</span>
                    <span v-if="item.sellUnit">/{{ item.sellUnit }}</span>
                  </template>
                  <template v-else>
                    <span>均价¥{{ Number(item.averagePrice) | NumFormat }}</span>
                    <span v-if="item.sellUnit">/{{ item.sellUnit }}</span>
                  </template>
                </div>
                <div class="product-price" v-else>
                  <span>¥{{ Number(item.unitPrice) | NumFormat }}</span>
                  <span v-if="item.sellUnit">/{{ item.sellUnit }}</span>
                </div>

                <!-- 商品数量 -->
                <div class="product-num" v-if="item.superDiscountProductPrice">
                  <div class="item">
                    <span>￥{{ item.superDiscountProductPrice }} (特价)</span>
                    <span v-if="item.isMediumPackage === 1">×{{ item.superDiscountProductNum * item.mediumPackage }}{{ item.sellUnit }}</span>
                    <span v-else>×{{ item.superDiscountProductNum }}{{ item.sellUnit }}</span>
                  </div>
                  <div class="item" v-if="item.fullReductionProductPrice">
                    <span>￥{{ item.fullReductionProductPrice }} (活动价)</span>
                    <span v-if="item.isMediumPackage === 1">×{{ item.fullReductionNum * item.mediumPackage }}{{ item.sellUnit }}</span>
                    <span v-else>×{{ item.fullReductionNum }}{{ item.sellUnit }}</span>
                  </div>
                  <div class="item" v-if="item.oldPriceNew">
                    <span>￥{{ item.oldPriceNew }} (原价)</span>
                    <span v-if="item.isMediumPackage === 1">×{{ item.oldNum * item.mediumPackage }}{{ item.sellUnit }}</span>
                    <span v-else>×{{ item.oldNum }}{{ item.sellUnit }}</span>
                  </div>
                </div>

                <div class="product-num" v-else>
                  <!--中包装品种-->
                  <template v-if="item.isMediumPackage == 1">
                    <p style="font-size: 12px">中袋装：(1中包装={{item.mediumPackage}}{{ item.sellUnit }})</p>
                    <span class="num">(实际购买{{ item.totalNumber * item.mediumPackage }}{{ item.sellUnit }})</span>
                  </template>
                  <!--非中包装品种-->
                  <span v-else>×{{ item.totalNumber }}{{ item.sellUnit }}</span>
                </div>

                <!-- 小计金额 -->
                <div class="product-subtotal">
                  <p class="subtotal-first">¥{{ item.subtotalMoney }}</p>
                  <p class="subtotal-last" v-if="item.preferentialTotalMoney != null">
                    优惠¥{{ item.preferentialTotalMoney }}
                  </p>
                </div>

                <!--发货数量-->
                <div class="md-product-practical" v-if="data.orderStatus == '03' || data.orderStatus == '04'">
                  {{ item.deliveryNumber }}
                </div>

                <div class="product-handle">
                  <p v-if="item.isGift != 1" @click="addCart(item)">加入购物车</p>

                  <b-tooltip position="is-bottom" multilined type="is-light" :triggers="['click']"
                             :auto-close="['outside', 'escape']">
                    <div class="lookDetails">
                      <p>查看详情</p>
                      <div class="corner"></div>
                    </div>
                    <template v-slot:content>
                      <div class="tip-item">
                        <b style="width: 50%">生产批号</b>
                        <b style="width: 50%">数量</b>
                        <b style="width: 50%">最后退货时间</b>
                      </div>
                      <div class="tip-item" v-for="(list, listIndex) in item.orderLineBatchVos" :key="listIndex">
                        <b style="width: 50%">{{ list.productionBatchNumber }}</b>
                        <b style="width: 50%">{{ list.batchDeliveryNumber }}</b>
                        <b style="width: 50%">{{ list.lastReturnTime }}</b>
                      </div>
                      <div v-if="item.orderLineBatchVos.length == 0" style="margin: 10px 0">
                        暂无数据
                      </div>
                    </template>
                  </b-tooltip>
                </div>
              </div>

              <!--赠品信息-->
              <div style="display: flex; align-items: center; justify-content: space-between; width: 100%; margin-top: 10px;" v-if="item.gift">
                <div class="product-info">
                  <div class="product-info-main">
                    <div class="product-img-box" style="background: none; height: 70px;">
                      <div class="product-img product-info_img">
                        <img style="width: 70px; height: 70px;" :src="item.gift.picPath" alt/>
                        <div class="product-info_giveaway">赠品</div>
                      </div>
                    </div>
                    <div class="product-introduce" style="display: flex; flex-direction: column; justify-content: center;">
                      <p>{{ item.gift.productName }}【{{ item.gift.specifications }}】</p>
                      <p>{{ item.gift.produceFactory }}</p>
                    </div>
                  </div>
                </div>

                <div class="product-price" style="height: 70px;">￥{{ item.gift.unitPrice }}</div>
                <div class="product-num" style="height: 70px;">×{{ item.gift.totalNumber }}</div>
                <div class="product-subtotal" style="height: 70px;">￥{{ item.gift.subtotalMoney }}</div>
                <div class="md-product-practical" style="height: 70px;" v-if="data.orderStatus == '03' || data.orderStatus == '04'">{{ item.gift.deliveryNumber }}</div>
                <div class="product-handle" style="height: 70px;">
                  <b-tooltip position="is-bottom" multilined type="is-light" :triggers="['click']"
                             :auto-close="['outside', 'escape']">
                    <div class="lookDetails">
                      <p>查看详情</p>
                      <div class="corner"></div>
                    </div>
                    <template v-slot:content>
                      <div class="tip-item">
                        <b style="width: 50%">生产批号</b>
                        <b style="width: 50%">数量</b>
                        <b style="width: 50%">最后退货时间</b>
                      </div>
                      <div class="tip-item" v-for="(list, listIndex) in item.gift.giftBatchNumber" :key="listIndex">
                        <b style="width: 50%">{{ list.productionBatchNumber }}</b>
                        <b style="width: 50%">{{ list.deliveryNumber }}</b>
                        <b style="width: 50%">{{ list.lastReturnTime }}</b>
                      </div>
                      <div v-if="item.orderLineBatchVos.length == 0" style="margin: 10px 0">
                        暂无数据
                      </div>
                    </template>
                  </b-tooltip>
                </div>
              </div>
            </div>
          </div>

          <div v-if="productListRst.length == 0" style="margin: 10px 0">
            暂无数据
          </div>
        </div>
      </div>
      <div class="loding" v-if="popupStatus">
        <div class="loding-wrap">
          <div class="loding-title">申请开票</div>
          <div class="form">
            <b-field horizontal label="发票抬头" :type="danger ? 'is-danger' : ''" :message="dangerMessage ? '不能为空' : ''">
              <b-input name="subject" @blur="blurDanger" v-model="info.rise" placeholder="请输入" expanded
                icon-right="close-circle" icon-right-clickable @icon-right-click="clearIconClick">
              </b-input>
              <div class="form-management" @click="handleManagement">
                管理发票抬头
              </div>
            </b-field>
            <b-field horizontal label="纳税人识别号" :type="duty ? 'is-danger' : ''" :message="dutyMessage ? '请输入正确税号' : ''">
              <b-input name="subject" expanded v-model="info.duty" @blur="blurDuty" placeholder="请输入">
              </b-input>
            </b-field>
            <b-field horizontal label="备注" class="textareas">
              <b-input type="textarea" v-model="info.remark"></b-input>
            </b-field>
            <div class="mode">
              <p class="mode-title">开票方式</p>
              <b-radio v-model="radio" name="电子发票" type="is-info" v-for="item in radioList" :key="item.id"
                :native-value="item.value">
                {{ item.name }}
              </b-radio>
            </div>
            <div class="buttons">
              <span class="form-cancel" @click="handleCancel">取消</span>
              <span class="form-confirm" @click="handleConfirm">确定</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <PageBottom></PageBottom>
    <addCarModal ref="addCarModalRef" :p_obj="p_obj"/>
    <DownloadInspectionReport ref="DownloadInspectionReportRef" :commodityList="list" :data="data" />
    <b-modal
        v-model="showCancel"
        scroll="keep"
        has-modal-card
        trap-focus
        :destroy-on-hide="false"
        aria-role="dialog"
        aria-modal
        style="z-index: 1001;"
    >
      <template #default="props">
        <div class="inner-dialog inner-dialog-balance">
          <div>
            <h4 class="groupTitle">
              <span>提示</span>
              <button type="button" class="delete" @click="closeCancel" />
            </h4>
            <div class="groupCont balanceCont">
              {{ showMsg }}
            </div>
            <div class="footer">
              <b-button type="concel" @click="closeCancel">取消</b-button>
              <b-button type="apply" :disabled="kg" @click="confirmCancel"> {{kg ? countTime+'s' : '确认' }} </b-button>
            </div>
          </div>
        </div>
      </template>

      <!--过期/临期资质弹窗-->
      <qualificationsDialog ref="qualificationsDialogRef" @continueToAddPurchases="continueToAddPurchases" />
    </b-modal>
  </div>
</template>

<script>
import TrackNode from "@/components-v2/trackNode.vue";

const dutyReg = /^[A-Za-z0-9]+$/;
import { AccountStorage } from "@services/account";
import { Countdown } from "@components/countdown";

import PageTop from "@/components-v2/layout/page-top"
import PageTop1 from "@/components-v2/layout/page-top1"
import PageBottom from "@/components-v2/layout/page-bottom"
import addCarModal from "@/components-v2/addCarModal/addCarModal";
import DownloadInspectionReport from '@/components-v2/DownloadInspectionReport.vue'
import qualificationsDialog from '@/components-v2/qualificationsDialog.vue'

import publicMethods from '@mixin/publicMethods'
import qualificationsPopup from '@mixin/qualificationsPopup'
import { urlDownloadFile } from '@/utils'

const accountStorage = new AccountStorage();

const PayStatus = {
  WAIT: "待支付",
  PAYING: "支付中",
  SUCCESS: "支付成功",
  FAIL: "支付失败",
  CLOSED: "已失效",
  REFUNDED: "已退款"
};

export default {
  components: {
    TrackNode,
    Countdown,
    PageTop,
    PageTop1,
    PageBottom,
    addCarModal,
    DownloadInspectionReport,
    qualificationsDialog
  },
  data() {
    return {
      payStatus: PayStatus,
      large: '',//搜索
      productListRst: [],//搜索结果
      data: {
        orderStatus: '',
        waybillNo: ''
      },
      list: [],
      info: {
        rise: null,
        duty: "",
        remark: ""
      },
      radio: "",
      radioList: [
        {
          id: 1,
          value: 1,
          name: "电子发票"
        },
        {
          id: 2,
          value: 2,
          name: "纸质发票"
        }
      ],
      popupStatus: false,
      danger: false,
      dangerMessage: false,
      duty: false, // 税号校验
      dutyMessage: false,
      productIds: [],
      userId: "",
      ids: [],
      arr: [], // 选中的文件数
      p_obj:{},
      showCancel:false,
      showMsg:"",
      countTime:0,
      timer:null,
        readMoreTxt:"查看完整物流信息",
        isShowMore:false,
        logisticsTrackHeight:'0',
        tracesData: [],
      kg:true //倒计时按钮是否可以点击 true不能点击
      // isComponentModalActive: false
    };
  },
  filters: {
    statusName(type) {
      switch (type) {
        case "01":
          return "待支付";
        case "02":
          return "待发货";
        case "03":
          return "运输中";
        case "04":
          return "已完成";
        case "05":
          return "已取消";
      }
    },
    unit(type) {
      let a = type.indexOf("*");
      return type.substring(1, a) * type.substring(a + 1, type.length - 1);
    },
    orderSource(type) {
      switch (type) {
        case "1":
          return "商城下单";
        case "2":
          return "erp同步";
      }
    },
    orderTypeName(type) {
      switch (type) {
        case "1":
          return "电商下单";
        case "2":
          return "拉单厂家";
        case "3":
            return "开票";
        case "4":
            return "业务员下单";
        case "5":
            return "药师帮";
        case "6":
            return "门店采购计划";
      }
    },
    distributionWay(type) {
      switch (type) {
        case "1":
          return "自有配送";
        case "2":
          return "京东配送";
        case "3":
          return "其他托运";
        case "4":
          return "仓库自提";
        case "5":
          return "门市自提";
      }
    },
    payWay(type) {
      let statusObj = {
        ONLINE: "线上支付",
        ALIPAY: "支付宝支付",
        WXPAY: "微信支付",
        COD: "货到付款",
        ZSPAY:"对公支付 (招行)",
        CQNCSYPAY:"对公支付 (农商行)",
        JTPAY: '对公支付（交行）',
        NYPAY: '对公支付（农行）',
        OFFLINEPAY: '线下付款'
      };
      return statusObj[type];
    },
    invoice(type) {
      let statusObj = {
        1: "是",
        2: "否"
      };
      return statusObj[type];
    },
      filterMoney1(val) {
          return Number(val.subtotalMoney/(val.totalNumber * val.mediumPackage)).toFixed(2)
      }
  },
  mixins: [publicMethods, qualificationsPopup],
  created() {
    let newUserId = accountStorage.getCacheUserInfo();
    this.userId = newUserId.userId;
    this.getOrderDetail();
    this.searchProduct()
  },

  methods: {
    searchProduct(value) {
      // 若未输入值，则展示所有数据
      if (null === value || undefined === value) {
        this.productListRst = this.list;
      } else {
        this.productListRst = []; // 结果列表置空
        let regStr = '';
        // 初始化正则表达式
        for (let i = 0; i < value.length; i++) {
          regStr = regStr + '(' + value[i] + ')([\\s]*)';
        }
        let reg = new RegExp(regStr);

        for (let i = 0; i < this.list.length; i++) {
          let name = this.list[i].productName; //按照名字匹配
          let regMatch = name.match(reg);
          if (null !== regMatch) {// 将匹配的数据放入结果列表中
            this.productListRst.push(this.list[i]);
          }
        }
      }
    },
    //查看完整物流信息
    readMore(){
        this.isShowMore = !this.isShowMore
        if (this.isShowMore){
            this.logisticsTrackHeight = 'auto'
        }else{
            this.logisticsTrackHeight = '75px'
        }
    },
    //物流信息
    async emsInfo(item){
        let url = `/pjyy-deepexi-order-center/api/v2/order/OcOrder/getInfoWallbillByOrder`;
        let params = {
            waybillNo: item.waybillNo,
            type: item.logisticsCompany,
        };
        const result = await this.$getHttpClient().get(url, { params });
        if (result && result.data.code == "200") {
            for (let i in result.data.data){
                if (i == result.data.data.length - 1){
                    result.data.data[i].isActive = true
                }else{
                    result.data.data[i].isActive = false
                }
            }
            this.tracesData = result.data.data
            if (this.tracesData.length > 0){
                this.logisticsTrackHeight = '75px'
            }
        }
    },
    copyData(code) {
      // 创建输入框元素
      let oInput = document.createElement('input');
      // 将想要复制的值
      oInput.value = code;
      // 页面底部追加输入框
      document.body.appendChild(oInput);
      // 选中输入框
      oInput.select();
      // 执行浏览器复制命令
      document.execCommand('Copy');
      // 弹出复制成功信息
      this.$buefy.toast.open("已复制成功");
      // 复制后移除输入框
      oInput.remove();
    },
    //查看返利详情
    lookDetails() {
      let orderInfo = {
        orderNumber: this.$route.params.orderNumber,
        giftAmount: this.data.giftAmount
      };
      orderInfo = JSON.stringify(orderInfo);
      this.$router.push({
        name: "orderDetailRebate",
        query: {
          orderInfo: orderInfo
        }
      });
    },
    // 点击头部订单
    toBack() {
      this.$router.push({
        name: "v2-information",
      });
    },
    clearIconClick() {
      this.info.rise = null;
    },
    // 订单详情
    getOrderDetail() {
      this.list = [];
      let id = this.$route.params.orderNumber;
      let url = `/pjyy-deepexi-order-center/api/v1/order/OcOrderLine/orderLineList?orderNumber=${id}`;
      this.$getHttpClient().get(url)
        .then(res => {
          this.data = res.data.data || {orderLines: []};
          if (this.data.waybillNo){
              this.emsInfo(this.data)
          }
          this.list = this.data.orderLines;
          this.productListRst = this.list;
          this.orderId = res.data.data.id;
        });
    },
    // 重新购买
    anewPurchase(item) {
      let arr = [];
      item.orderLines.forEach(ele => {
        arr.push(ele.productId);
      });
      let url = `/pjyy-deepexi-order-center/api/v1/cart/againAddGoodsToCartList`;
      let data = {
        orderNumber: item.orderNumber
      };
      this.$getHttpClient()
        .post(url, data)
        .then(res => {
          if (res.data.code === "200") {
            this.$buefy.toast.open({
              message: "操作成功",
              type: "is-success"
            });
            this.getOrderDetail();
            this.$router.push({
              name: "v2-shopcard"
            });
          } else if(res.data.code == "8501"){
              this.p_obj = res.data.payload;
              if (this.$refs.addCarModalRef) {
                this.$refs.addCarModalRef.showModal()
              }
          }
        });
    },
    // 再次购买
    againPurchase(item) {
      let arr = [];
      item.orderLines.forEach(ele => {
        arr.push(ele.productId);
      });
      let url = `/pjyy-deepexi-order-center/api/v1/cart/againAddGoodsToCartList`;
      let data = {
        orderNumber: item.orderNumber
      };
      this.$getHttpClient()
        .post(url, data)
        .then(res => {
          if (res.data.code === "200") {
            this.$buefy.toast.open({
              message: "操作成功",
              type: "is-success"
            });
            this.getOrderDetail();
            this.$router.push({
              name: "v2-shopcard"
            });
          } else if(res.data.code == "8501"){
              this.p_obj = res.data.payload;
              if (this.$refs.addCarModalRef) {
                this.$refs.addCarModalRef.showModal()
              }
          }
        });
    },
    //随货同行单和检验报告
    handleWithGoodTicket(val){
        if (val === 1){
            let api = `/pjyy-deepexi-order-center/accompanyingBill/dynamicAssignment/${this.data.erpSaleOrderId}`;
            this.$getHttpClient().get(api).then(res => {
                let { code, data } = res.data;
                if (code == 200 && data.pdfUrl) {
                    window.open(data.pdfUrl);
                }else{
                    this.$buefy.toast.open({
                        message: res.data.msg,
                        type: "is-danger"
                    });
                }
            });
        }else{//下载随货同行单附检验报告
            this.list.forEach(item => {
                this.productIds.push(item.productId);
            });
            let data = {
                productIds: this.productIds,
                orderId: this.data.id ? this.data.id.toString() : '',
                saleNo: this.data.erpOrderId
            };
            let url = `/pjyy-deepexi-product-center/api/v1/product/drug/downloadPeerAndDrugPdfPC`;
            this.$getHttpClient().post(url, data).then(res => {
                if (res.data.code === "200") {
                  let url = res.data.data;
                  let arr = url.split('/')
                  let str = arr[arr.length - 1]
                  arr = str.split('随货同行单')
                  urlDownloadFile(url, `${arr[0]}随货同行单(附检验报告)`)
                }else{
                    this.$buefy.toast.open({
                        message: res.data.msg,
                        type: "is-danger"
                    });
                }
            });
        }
    },
    showTicket(type) {
      // 查看发票
      if (type == 1) {
        this.$router.push({
          name: 'v2-electronicInvoiceList',
          params: {
            orderNumber: this.data.orderNumber
          }
        });
      } else if (type == 2) {
        // 下载开货明细
        this.$router.push({
          name: 'v2-shoppingDetails',
          params: {
            orderNumber: this.data.orderNumber
          }
        });
      }
    },
    countdown() {
      let vm = this
      //修改倒计时时间 这里写了10s
      vm.countTime = 5
      // 清除之前的计时器
      clearInterval(vm.timer)
      vm.timer = null
      vm.kg= true;
      vm.timer = setInterval(function() {
        //每一秒自减一
        vm.countTime--
        if (vm.countTime == 0) {
          //这里的kg是用来判断显示什么按钮的，在data中需要声明
          vm.kg= false;
          // 去掉计时器 很重要一定要去掉
          clearInterval(vm.timer)
          vm.timer = null
        }
      }, 1000);
    },
    //确认取消
    confirmCancel(){
      this.confirmCloseOrder(this.data, 'detail');
    },
    closeCancel(){
      let vm = this
      vm.showMsg = ""
      vm.showCancel = false
    },
      //新取消订单
    async onCloseOrder() {
        let _this = this;
        const URL = `/pjyy-deepexi-erp-api-center/api/v1/erp/order/queryPreCancelOrderAssociationOrder/${_this.data.erpOrderId}`;
        const result = await _this.$getHttpClient().get(URL);
        if (result.data.code == "200"){
            if (result.data.data && result.data.data.isShow){
              _this.showMsg = "如果取消该订单，相关联取消的订单有：" + result.data.data.showMsg + "，是否继续？"
              _this.showCancel = true
              _this.countdown()
            }else{
              _this.showMsg = "是否确认取消订单？"
              _this.showCancel = true
              _this.countdown()
            }
        }
    },
    // 下载报告
    handleDownload(val) {
      this.$refs.DownloadInspectionReportRef.getInspectionReport(val)
    },
    // 立即付款
    handlePayment(orderNumber) {
      this.$router.push({
        name: "payMent",
        params: {
          orderId: orderNumber,
          type:"order"
        }
      });
    },
    //获取用户ID
    getUserId() {
      let userInfo = accountStorage.getCacheUserInfo();
      if (userInfo) {
        return userInfo.userId;
      }
    },
    // 加入购物车
    addCart(item) {
      if (this.qualificationsPopup(1, null, item)) {
        return;
      }

      const URL = "/pjyy-deepexi-order-center/api/v1/cart/addGoodsToCartList";
      const data = {
        productId: Number(item.productId),
        userId: Number(this.getUserId()),
        totalNumber: item.totalNumber
      };
      this.$getHttpClient()
        .post(URL, data)
        .then(res => {
          if (res.data.code === "200") {
            let { data } = res.data;
            // 如果有资质过期的数据，显示资质过期弹窗
            if (data.certificateList && data.certificateList.length) {
              this.qualificationsPopup(2, data)
              return
            }

            this.$buefy.toast.open({
              message: "加入成功",
              type: "is-success"
            });
            this.$router.push({
              name: "v2-shopcard"
            });
          } else if(res.data.code == "8501"){
              this.p_obj = res.data.payload;
              if (this.$refs.addCarModalRef) {
                this.$refs.addCarModalRef.showModal()
              }
          }
        });
    },
    blurDanger() {
      if (this.info.rise === null) {
        this.danger = true;
        this.dangerMessage = true;
      } else {
        this.danger = false;
        this.dangerMessage = false;
      }
    },
    // 税号
    blurDuty() {
      if (dutyReg.test(this.info.duty) && this.info.duty !== "") {
        this.duty = false;
        this.dutyMessage = false;
      } else {
        this.duty = true;
        this.dutyMessage = true;
      }
    },
    handleLoding() {
      document.body.style.height = `${document.documentElement.clientHeight}px`;
      document.body.style.overflow = "hidden";
      this.popupStatus = true;
    },
    handleCancel() {
      document.body.style.height = "";
      document.body.style.overflow = "";
      this.popupStatus = false;
    },
    // 申请开票的确定按钮
    handleConfirm() {
      if (this.info.rise === null) {
        this.danger = true;
        this.dangerMessage = true;
        return;
      }
      if (dutyReg.test(this.info.duty) && this.info.duty !== "") {
        this.duty = false;
        this.dutyMessage = false;
      } else {
        this.duty = true;
        this.dutyMessage = true;
        return;
      }
      let url = `/pjyy-deepexi-order-center/api/v1/order/OcOrder/OrderInvoice`;
      let data = {
        id: this.$route.params.id,
        invoiceName: this.info.rise,
        invoiceIdentify: this.info.duty,
        invoiceRemark: this.info.remark,
        invoiceType: String(this.radio)
      };
      this.$getHttpClient()
        .post(url, data)
        .then(res => {
          if (res.data.code === "200") {
            this.$buefy.toast.open({
              message: "申请开票成功",
              type: "is-success"
            });
          }
        });
      document.body.style.height = "";
      document.body.style.overflow = "";
      this.popupStatus = false;
    },
    // 取消订单
    updateOrderStatus(orderNumber) {
      let url = `/pjyy-deepexi-order-center/api/v1/order/OcOrder/updateOrderStatus05?orderNumber=${orderNumber}`;
      this.$getHttpClient()
        .get(url)
        .then(res => {
          if (res.data.code === "200") {
            this.getOrderDetail();
          }
        });
    },
    // 跳转发票抬头
    handleManagement() {
      this.$router.push({
        path: `invoice`
      });
    },
    // 售后退货
    handleSale(data) {
      this.$router.push({
        name: "salesDetail",
        params: {
          status: "1",
          orderNumber: data.orderNumber
        }
      });
    }
  },
  updated() { }
};
</script>
<style src="./order-detail.less" lang="less" scoped>

</style>
